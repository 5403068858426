<template>
  <div>
    <a-table
        :columns="tableList"
        :data-source="list"
        :pagination="pagination"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   订单   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <span @click="handleGoToOrder(row.orderNo)" class="cur-pot color-blue">{{ row.orderNo }}</span>
      </div>
<!--   拍品瑕疵   -->
      <div slot="itemRemarkSlot" slot-scope="row" v-if="row.productRemark">
        <div>平台定义瑕疵：{{ computedRemarkShow(row.productRemark).dqRemark }}</div>
        <div>用户定义瑕疵：{{ computedRemarkShow(row.productRemark).userRemark }}</div>
      </div>
<!--   送评类型   -->
      <div slot="itemOrderRateServicePayTypeSlot" slot-scope="row">
        <a-tooltip>
          <template slot="title">
            {{ computedRateServicePayTypeTextByDesc(row.orderRatingServiceRank) }}
          </template>
          <span :class="dqCheckPayClassType[row.orderRatingServiceRank]">{{ computedRateServicePayTypeText(row.orderRatingServiceRank) }}</span>
        </a-tooltip>
      </div>
<!--   评级结果   -->
      <div slot="itemCheckResultSlot" slot-scope="row">
        <span :class="rateResultClassType[row.identificationResults]">{{ computedResultTypeText(row.identificationResults) || '暂无' }}</span>
      </div>
<!--   评级编号   -->
      <div slot="itemRatingCodeSlot" slot-scope="row">
        <span>{{ row.ratingCode }}</span>
        <div>
          <a-button
            type="primary"
            class="mt-10"
            size="small"
            @click="handleShowChangeRatingCodePopup(row)"
          >编辑</a-button>
        </div>
      </div>
<!--   鉴定后买家选择   -->
      <div slot="itemBuyerSelectedSlot" slot-scope="row">
        <span :class="buyerSelAfterRatingClassType[row.buyerSelAfterRating]">{{ computedBuyerSelAfterRatingTypeListMapText(row.buyerSelAfterRating) }}</span>
      </div>
<!--   发货单号   -->
      <div slot="itemSenderExpressNoSlot" slot-scope="row">
        <span :class="row.senderExpressNo ? 'color-green cur-pot' : 'color-gray'" >{{ row.senderExpressNo ? row.senderExpressNo : '未发货' }}</span>
      </div>
<!--   退货时保价选择   -->
      <div slot="itemReturnSelectedSlot" slot-scope="row">
        <span :class="buyerSelAfterRatingClassType[row.sellerSelReturnAfterBuyer]">{{ computedSellerSelReturnAfterBuyerTypeListMapText(row.sellerSelReturnAfterBuyer) }}</span>
      </div>
<!--   平台确认   -->
      <div slot="itemDqConfirmSlot" slot-scope="row">
        <span :class="confirmClassType[row.reviewStatus]">{{ computedConfirmTypeText(row.reviewStatus) }}</span>
      </div>
<!--   操作  :disabled="row.reviewStatus === 1" -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button
            size="small"
            type="primary"
            @click="handleShowConfirm(row)"
        >请确认</a-button>
      </div>
    </a-table>
    <ConfirmDetailPopup ref="confirmDetailPopupEl" @success="handleConfirmSuccess"/>
<!--  编辑评级编号  -->
    <EditorRatingCodePopup
      ref="editorRatingCodePopupEl"
      rate-type="ABC"
      @change="handleConfirmSuccess"
    />
  </div>
</template>
<script>
import {
  buyerSelAfterRatingClassType,
  buyerSelAfterRatingTypeListMapText,
  CONFIRM_CLASS_TYPE,
  dqCheckPayClassType,
  RATE_RESULT_CLASS_TYPE_LIST,
  rateConfirmTypeMapText,
  rateResultTypeMapText,
  rateServicePayTypeMapText,
  rateServicePayTypeMapTextByDesc,
  sellerSelReturnAfterBuyerTypeListMapText
} from "@/views/cmsPage/checkManage/_data"
import {dqCheckResultConfirmListTableColumns} from "@/views/cmsPage/checkManage/resultList/_data"
import ConfirmDetailPopup from "@/views/cmsPage/checkManage/resultList/_components/ConfirmDetailPopup"
import EditorRatingCodePopup from "@/views/cmsPage/checkManage/_components/EditorRatingCodePopup"
import {productRemarkStringParseToJson} from "@/_service"

export default {
  props: ['list', 'pagination'],
  components: {ConfirmDetailPopup, EditorRatingCodePopup},
  data() {
    return {
      tableList: dqCheckResultConfirmListTableColumns,
      rateResultClassType: RATE_RESULT_CLASS_TYPE_LIST,
      confirmClassType: CONFIRM_CLASS_TYPE,
      dqCheckPayClassType,
      buyerSelAfterRatingClassType
    }
  },
  computed: {
    computedRemarkShow() {
      return (str) => productRemarkStringParseToJson(str)
    },
    computedRateServicePayTypeTextByDesc() {
      return (value) => rateServicePayTypeMapTextByDesc(value)
    },
    computedBuyerSelAfterRatingTypeListMapText() {
      return (value) => buyerSelAfterRatingTypeListMapText(value)
    },
    computedSellerSelReturnAfterBuyerTypeListMapText() {
      return (value) => sellerSelReturnAfterBuyerTypeListMapText(value)
    },
    computedRateServicePayTypeText() {
      return (value) => rateServicePayTypeMapText(value)
    },
    computedResultTypeText() {
      return (value) => rateResultTypeMapText(value)
    },
    computedConfirmTypeText() {
      return (value) => rateConfirmTypeMapText(value)
    }
  },
  methods: {
    /** 修改评级编号 */
    handleShowChangeRatingCodePopup(row) {
      const tempData = {
        ratingCode: row.ratingCode,
        ratingId: row.recordId
      }
      this.$refs.editorRatingCodePopupEl.show(tempData)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 去往订单页面 */
    handleGoToOrder(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/ordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    /** 展示详情 */
    handleShowConfirm(row) {
      this.$refs.confirmDetailPopupEl.showPopup(
          row.recordId,
          row.orderId,
          row.productRemark,
          row.orderRatingServiceRank,
          row.productName,
          row.identificationResults,
          row.orderPrice,
          row.biaobanStatus
      )
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        current: index,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    handleConfirmSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
.color-green {
  color: green;
}
.color-red {
  color: red;
}
</style>