<template>
  <div>
    <a-modal
        :width="800"
        v-model="show"
        @cancel="resetForms"
        @ok="hideModal"
    >
      <template #title>
        <div class="flex-sp-bt-center">
          <div>确认复评结果</div>
          <a-button v-if="!detailData.boxCode" class="mr-40" key="reset" @click="getDetailData(recordId)">重新获取</a-button>
          <a-popconfirm v-if="detailData.boxCode" title="确定刷新评级结果？" @confirm="handleRefreshRateResult">
            <a-button class="mr-40" key="refresh" type="danger">刷新评级结果</a-button>
          </a-popconfirm>
        </div>
      </template>
      <template #footer>
        <div class="flex-sp-bt-center">
          <div class="flex-start-center">
            <a-popconfirm
                title="确定将鉴定操作回退？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleCheckBack"
            >
              <a-button key="submit" type="danger">回退鉴定操作</a-button>
            </a-popconfirm>
          </div>
          <div class="flex-end-center" v-if="confirmReviewStatus !== 2">
            <a-button key="back" @click="resetForms">取消</a-button>
            <a-popconfirm
                title="一经确定将不可回退，确定操作？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="hideModal"
            >
              <a-button key="submit" type="primary">确定</a-button>
            </a-popconfirm>
          </div>
          <div v-else></div>
        </div>
      </template>
      <div class="popup-content">
        <div class="content-title">
          <div class="title-result-text">
            评级结果：
            <span
                :class="['赝品', '瑕疵', '备注', '存疑'].includes(identificationResults) ? 'color-red' : 'color-green'"
            >{{ identificationResults || '暂无' }}</span>
          </div>
          <div class="title-result-text ml-20">评级内容：<span>{{ detailData.remark || '暂无' }}</span></div>
          <div class="title-result-text ml-20">赔付等级：<span>{{ detailData.payLevel || '暂无' }}</span></div>
          <div class="title-result-text ml-20">分数：<span>{{ detailData.score || '暂无' }}</span></div>
          <div class="title-result-text ml-20">评级尺寸：<span>{{ detailData.size || '暂无' }}</span></div>
          <div class="title-result-text ml-20">
            版别：
            <span>{{ detailData.title }}</span>
            <span class="ml-20 color-red">{{ detailData.version }}</span>
          </div>
          <div class="title-result-text">评级编号：<span class="text-underline">{{ detailData.boxCode }}</span></div>
        </div>
        <div class="content-result-img">
          <div class="result-img-list">
            <div class="result-img-title ft-sz-15">评级图片</div>
            <div class="result-img-list">
              <img
                  v-for="(item, index) in detailData.officialWebsiteImage"
                  :key="index"
                  :src="item"
                  alt=""
                  @click="handlePreview(detailData.officialWebsiteImage, index)"
              />
            </div>
          </div>
        </div>
        <div class="content-check-img">
          <div class="result-img-list">
            <div class="result-img-title">质检图片</div>
            <div class="result-img-list">
              <img
                  v-for="(item, index) in detailData.qualityInspectionImage"
                  :key="index"
                  :src="item"
                  alt=""
                  @click="handlePreview(detailData.qualityInspectionImage, index)"
              />
            </div>
          </div>
        </div>
        <div class="content-check-img">
          <div class="result-img-list">
            <div class="result-img-title">产品图片</div>
            <div class="result-img-list">
              <img
                  v-for="(item, index) in detailData.productImage"
                  :key="index"
                  :src="item"
                  alt=""
                  @click="handlePreview(detailData.productImage, index)"
              />
            </div>
          </div>
        </div>
        <div class="content-check-img">
          <div class="result-img-list mt-10" v-if='productName'>
            <div class="result-img-title">拍品名称：</div>
            <span class="color-red">{{ productName || '无' }}</span>
          </div>
          <div class="result-img-list">
            <div class="result-img-title">拍品尺寸：</div>
            <span class="color-red">{{ detailData.productSize || '卖家没有填写拍品尺寸' }}</span>
          </div>
          <div class="result-img-list">
            <div class="result-img-title">拍品瑕疵：</div>
            <span>平台定义瑕疵：<span class="color-red">{{ computedRemarkShow(productRemark) ? computedRemarkShow(productRemark).dqRemark : '暂无' }}</span></span>
            <span class="ml-20">用户定义瑕疵：<span class="color-red">{{ computedRemarkShow(productRemark) ? computedRemarkShow(productRemark).userRemark : '暂无' }}</span></span>
          </div>
          <div class="result-img-list">
            <div class="result-img-title">拍品描述：</div>
            <span :class="detailData.center ? 'color-red' : 'color-gray'">{{ detailData.center || '卖家没有填写描述' }}</span>
          </div>
          <div class="result-img-list" v-if='orderPrice'>
            <div class="result-img-title">拍品价格：</div>
            <span class="">{{ '￥' + orderPrice }}</span>
          </div>
          <div class="result-img-list">
            <div class="result-img-title">是否标版：</div>
            <span class="color-green">{{ detailData.refundedBiaobanStatus === 1 ? '标版' : '无需标版' }}</span>
            <a-checkbox v-if="detailData.refundedBiaobanStatus !== 0" class="ml-20" :disabled="detailData.refundedBiaobanStatus === 2" :checked='checkReturnVersionPriceStatus' @change="handleChangeCheckBox">
              是否退标版费 <span v-if="detailData.refundedBiaobanStatus === 2" class="color-gray">（已退）</span>
            </a-checkbox>
          </div>
          <div class="result-img-list">
            <div class="result-img-title">退款原因：</div>
            <span class="color-red">{{ detailData.returnsReason || '暂无' }}</span>
          </div>
        </div>
        <div style="font-size: 20px" class="mt-10">
          <a-tooltip>
            <template slot="title">
              {{ computedRateServicePayTypeTextByDesc(orderRatingServiceRank) }}
            </template>
            <span :class="dqCheckPayClassType[orderRatingServiceRank]">{{ computedRateServicePayTypeText(orderRatingServiceRank) }}</span>
          </a-tooltip>
        </div>
<!--    确定评级    -->
        <a-divider>确认评级审核结果</a-divider>
        <div class="result-select-list">
          <a-select
              allowClear
              showSearch
              style="width: 150px;"
              v-model="params.reviewStatus"
              placeholder="选择结果类型"
              @change="handleChangeReviewStatus"
          >
            <a-select-option
                v-for="item of confirmResultTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-select
              v-if="showDescTypeStatus"
              allowClear
              showSearch
              style="width: 250px;margin-left: 10px;"
              v-model="params.responsibilityDetermine"
              placeholder="选择描述类型"
              @change="handleChangeDescTypeStatus"
          >
            <a-select-option
                v-for="item of computedWatchLevelTwoList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-select
              v-if="showAddressSelectedType"
              allowClear
              showSearch
              style="width: 150px;margin-left: 10px;"
              v-model="params.addressSelectedType"
              placeholder="选择给那个用户"
              @change="handleChangeAddressSelectedType"
          >
            <a-select-option
                v-for="item of addressSelectedTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </div>
        <div class="input-ratingPrice">
          评级费：
          <a-select
              allowClear
              showSearch
              style="width: 150px;margin-left: 10px;"
              v-model="params.ratingPrice"
              placeholder="选择评级费金额"
          >
            <a-select-option
                v-for="item of ratePriceLevelTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </div>
        <div class="input-ratingPrice" v-if="showSellCompensateBuyerPriceStatus">
          卖家是否补偿买家费用：
          <a-select
              allowClear
              showSearch
              style="width: 250px;margin-left: 10px;"
              v-model="params.sellerBear"
              placeholder="卖家是否补偿买家费用"
              @change="handleChangeSellerBear"
          >
            <a-select-option
                v-for="item of sellerCompensateBuyerPriceStatusTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-input
              v-if="params.sellerBear === 1"
              style="width: 200px;margin-left: 10px;"
              placeholder="补偿金额50的倍数"
              v-model="params.compensationAmount"
          ></a-input>
        </div>
        <div class="input-ratingPrice" v-if="showDeductAllBondPriceBySellerStatus">
          是否扣除卖家保证金：
          <a-select
              allowClear
              showSearch
              style="width: 250px;margin-left: 10px;"
              v-model="params.deductionOfFullAmount"
              placeholder="请选择是否扣除卖家保证金"
              @change="handleChangeDeductionOfFullAmount"
          >
            <a-select-option
                v-for="item of deductAllBondPriceBySellerStatusTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.value === 1 ? `${item.name}${detailData.depositMoneySham || 1000}` : item.name }}</a-select-option>
          </a-select>
        </div>
        <div class="result-desc-remark">
          <a-textarea
              placeholder='售后定责描述'
              v-model="params.reviewRemarks"
          ></a-textarea>
          <div class="color-red mt-10">*请谨慎填写</div>
          <div class="w-100-w mt-10" v-if="quickInputDescTextList.length">
            <a-tag
                v-for="(item, index) in quickInputDescTextList"
                :key="index"
                color="blue"
                class="mr-5 cur-pot mb-10"
                @click="handleInputDesc(item)"
            >{{ item }}</a-tag>
          </div>
        </div>
        <a-divider>地址</a-divider>
        <div class="result-detail-address">
          <div class="buyer-seller-address" v-if="buyerAddress && params.addressSelectedType === 1">
            <div class="address-title">买家地址：</div>
            <div class="address-info-text">用户姓名：<span>{{ buyerAddress.userName }}</span></div>
            <div class="address-info-text">用户电话：<span>{{ buyerAddress.telNumber }}</span></div>
            <div class="address-info-text">省市区：<span>{{ buyerAddress.provinceName + ' ' + buyerAddress.cityName + ' ' + buyerAddress.countyName }}</span></div>
            <div class="address-info-text">地址详情：<span>{{ buyerAddress.detailInfo }}</span></div>
          </div>
          <div class="buyer-seller-address" v-if="sellerAddress && params.addressSelectedType === 2">
            <div class="address-title">卖家地址：</div>
            <div class="address-info-text">用户姓名：<span>{{ sellerAddress.userName }}</span></div>
            <div class="address-info-text">用户电话：<span>{{ sellerAddress.telNumber }}</span></div>
            <div class="address-info-text">省市区：<span>{{ sellerAddress.provinceName + ' ' + sellerAddress.cityName + ' ' + sellerAddress.countyName }}</span></div>
            <div class="address-info-text">地址详情：<span>{{ sellerAddress.detailInfo }}</span></div>
          </div>
        </div>
        <a-divider>备注</a-divider>
        <div class="flex-sp-bt-start">
          <BaseItemCom title-text="补充备注（仅内部客服人员可见，不对外展示。记录客服人员的备注信息--正向）">
            <template #itemInfo>
              <OrderNoteListInfo :orderNoteList="orderNoteByForwardList" />
            </template>
            <template #itemBtn>
              <a-button @click="handleAddCenterNotesByForward(orderId)">新增备注</a-button>
            </template>
          </BaseItemCom>
        </div>
      </div>
    </a-modal>
<!--  添加内部备注  -->
    <AddNotePopup ref="addNotePopupEl" @success="getServiceNoteList('forward')" />
  </div>
</template>

<script>
import untils from "@/untils";
import {
  DEDUCT_ALL_BOND_PRICE_BY_SELLER_STATUS_TYPE_LIST, DESC_NOT_MATCH_LIST,
  dqCheckPayClassType, HAS_NOTES_LIST,
  quickInputDescTextList,
  RATE_RESULT_CLASS_TYPE_LIST,
  RATE_RESULT_TYPE_LIST, rateResultTypeMapText,
  rateServicePayTypeMapText,
  rateServicePayTypeMapTextByDesc, REMARK_TYPE_LIST,
  SELLER_COMPENSATE_BUYER_PRICE_STATUS_TYPE_LIST
} from "@/views/cmsPage/checkManage/_data"
import {
  ADDRESS_SELECTED_BY_SELLER_TYPE,
  ADDRESS_SELECTED_TYPE, AGAIN_RATE_PRICE_LEVEL_TYPE_LIST,
  DESC_TYPE_LIST,
} from "@/views/cmsPage/checkManage/resultList/_data"
import OrderNoteListInfo from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderNoteListInfo/index.vue"
import BaseItemCom from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/BaseItemCom/index.vue"
import _confirmDetailNotesMixin
  from "@/views/cmsPage/checkManage/resultList/_components/ConfirmDetailPopup/_mixins/_confirmDetailNotesMixin"
import AddNotePopup
  from "@/views/cmsPage/dqPlatform/orderListAboutManage/_components/OrderNoteListInfo/_components/AddNotePopup/index.vue"
import {productRemarkStringParseToJson} from "@/_service"
import {getRefreshRateResultApi, postConfirmRateCheckBackApi} from "@/views/cmsPage/checkManage/_apis"
export default {
  mixins: [_confirmDetailNotesMixin],
  components: {AddNotePopup, BaseItemCom, OrderNoteListInfo},
  data() {
    return {
      // 是否退标版费用
      checkReturnVersionPriceStatus: false,
      untils: untils,
      quickInputDescTextList,
      rateResultClassType: RATE_RESULT_CLASS_TYPE_LIST,
      show: false,
      detailData: '',
      dqCheckPayClassType,
      confirmResultTypeList: RATE_RESULT_TYPE_LIST,
      descTypeList: DESC_TYPE_LIST,
      showDescTypeStatus: false,
      addressSelectedTypeList: [],
      showAddressSelectedType: false,
      ratePriceLevelTypeList: AGAIN_RATE_PRICE_LEVEL_TYPE_LIST,
      sellerCompensateBuyerPriceStatusTypeList: SELLER_COMPENSATE_BUYER_PRICE_STATUS_TYPE_LIST,
      showSellCompensateBuyerPriceStatus: false,
      deductAllBondPriceBySellerStatusTypeList: DEDUCT_ALL_BOND_PRICE_BY_SELLER_STATUS_TYPE_LIST,
      showDeductAllBondPriceBySellerStatus: false,
      // 瑕疵
      remarkTypeList: REMARK_TYPE_LIST,
      // 描述不符
      descNotMatchList: DESC_NOT_MATCH_LIST,
      // 备注
      hasNotesList: HAS_NOTES_LIST,
      params: {
        // 结果类型
        reviewStatus: undefined,
        responsibilityDetermine: undefined,
        addressSelectedType: undefined,
        reviewRemarks: undefined,
        ratingPrice: undefined,
        sellerBear: undefined,
        deductionOfFullAmount: undefined,
        compensationAmount: undefined,
      },
      buyerAddress: '',
      sellerAddress: '',
      recordId: '',
      productRemark: '',
      isLoading: false,
      //0：无交易-自助评级， 1: 包评级-直接送评，2：包评级-延长送品，3：自助-直接送评，4：自助-延长送评
      rateServiceType: undefined,
      orderRatingServiceRank: undefined,
      productName: undefined,
      identificationResults: undefined,
      orderPrice: undefined,
      biaobanStatus: undefined,
      // 计算二级分类使用数据
      computedWatchLevelTwoList: [],
      orderNoteByForwardList: [], // 正向备注信息
      orderNoteByReverseList: [], // 逆向备注信息
      orderId: undefined,
      confirmReviewStatus: 1, // 确认评级状态
    };
  },
  watch: {
    'params.reviewStatus'(newVal, oldVal) {
      if (newVal === 20) {
        this.computedWatchLevelTwoList =  this.remarkTypeList
      } else if (newVal === 35) {
        this.computedWatchLevelTwoList = this.hasNotesList
      } else if (newVal === 40) {
        this.computedWatchLevelTwoList = this.descNotMatchList
      } else {
        this.computedWatchLevelTwoList = this.descTypeList
      }
    }
  },
  computed: {
    computedRateServicePayTypeTextByDesc() {
      return (value) => rateServicePayTypeMapTextByDesc(value)
    },
    computedRateServicePayTypeText() {
      return (value) => rateServicePayTypeMapText(value)
    },
    computedResultTypeText() {
      return (value) => rateResultTypeMapText(value)
    },
    computedRemarkShow() {
      return (str) => productRemarkStringParseToJson(str)
    }
  },
  methods: {
    /** 刷新评级过 */
    async handleRefreshRateResult() {
      this.$loading.show()
      const res = await getRefreshRateResultApi({boxCode: this.detailData.boxCode})
      if (res.status !== '200') return
      this.$message.info(res.message || '成功')
      setTimeout(async () => {
        await this.getDetailData(this.recordId)
      }, 1500)
    },
    /** 新增备注 */
    async handleAddCenterNotesByForward(orderId) {
      this.$refs.addNotePopupEl.show(orderId)
    },
    /** 改变单选，是否退标版费用 */
    handleChangeCheckBox(e) {
      this.checkReturnVersionPriceStatus = e.target.checked
    },
    /** 快速输入 */
    handleInputDesc(item) {
      this.params.reviewRemarks = item
    },
    /** 修改是否扣除全额保证金 */
    handleChangeDeductionOfFullAmount(e) {
      this.$forceUpdate();
    },
    /** 修改是否扣赔偿买家 */
    handleChangeSellerBear(e) {
      this.$forceUpdate();
    },

    /** 改变鉴定结果 */
    handleChangeReviewStatus(reviewType) {
      this.resetParams('reviewType')
      this.$nextTick(() => {
        if ([1, 2].includes(this.rateServiceType)) {
          if (reviewType === 5) {
            this.showDescTypeStatus = false
            this.showAddressSelectedType = true
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 1
            }
          } else if (reviewType === 20) {
            this.showDescTypeStatus = true
          } else if (reviewType === 25) {
            this.showAddressSelectedType = true
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 3
            }
            this.params.ratingPrice = 0
          } else if (reviewType === 30) {
            this.showAddressSelectedType = true
            this.params.addressSelectedType = 2
          } else if (reviewType === 35) {
            this.showDescTypeStatus = true
          } else if (reviewType === 40) {
            this.showDescTypeStatus = true
          }
        }
        if ([3, 4].includes(this.rateServiceType)) {
          if (reviewType === 5) {
            this.showAddressSelectedType = true
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 1
            }
            this.showSellCompensateBuyerPriceStatus = true
            this.params.sellerBear = 0
          } else if (reviewType === 20) {
            this.showDescTypeStatus = true
          } else if (reviewType === 25) {
            this.showAddressSelectedType = true
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 3
            }
            this.params.ratingPrice = 0
          } else if (reviewType === 30) {
            this.showAddressSelectedType = true
            this.params.addressSelectedType = 2
            this.showDeductAllBondPriceBySellerStatus = true
            this.params.deductionOfFullAmount = 1
            this.showSellCompensateBuyerPriceStatus = true
          } else if (reviewType === 35) {
            this.showDescTypeStatus = true
          } else if (reviewType === 40) {
            this.showDescTypeStatus = true
          }
        }
      })
    },
    /** 改变类型描述 */
    handleChangeDescTypeStatus(descType) {
      this.resetParams('descType')
      this.$nextTick(() => {
        if ([1, 2].includes(this.rateServiceType)) {
          this.showAddressSelectedType = true
          if (descType === 20) {
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 3
            }
          } else if (descType === 25) {
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 3
            }
          } else if (descType === 30) {
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 1
            }
          } else if ([35, 40].includes(descType)) {
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 3
            }
          }
        }
        if ([3, 4].includes(this.rateServiceType)) {
          this.showSellCompensateBuyerPriceStatus = true
          this.showDeductAllBondPriceBySellerStatus = true
          this.showAddressSelectedType = true
          if (descType === 20) {
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 3
            }
          } else if (descType === 25) {
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 3
            }
          } else if (descType === 30) {
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 1
            }
            this.showSellCompensateBuyerPriceStatus = true
          } else if ([35, 40].includes(descType)) {
            if (this.detailData.recurRatingByUserId === 2) {
              this.params.addressSelectedType = 2
            } else {
              this.params.addressSelectedType = 3
            }
          }
        }
      })

    },
    /** 改变选择地址 */
    handleChangeAddressSelectedType(e) {

    },
    resetParams(type) {
      if (type === 'reviewType') {
        this.params.responsibilityDetermine = undefined
        this.params.addressSelectedType = undefined
        this.params.ratingPrice = undefined
        this.params.sellerBear = undefined
        this.params.deductionOfFullAmount = undefined
        this.params.compensationAmount = undefined
        this.showAddressSelectedType = false
        this.showDescTypeStatus = false
        this.showDeductAllBondPriceBySellerStatus = false
        this.showSellCompensateBuyerPriceStatus = false

      }
      if (type === 'descType') {
        this.params.addressSelectedType = undefined
        this.params.ratingPrice = undefined
        this.params.sellerBear = undefined
        this.params.deductionOfFullAmount = undefined
        this.params.compensationAmount = undefined
        this.showAddressSelectedType = false
        this.showDeductAllBondPriceBySellerStatus = false
        this.showSellCompensateBuyerPriceStatus = false
      }
    },
    async showPopup(
        id,
        orderId,
        productRemark,
        orderRatingServiceRank,
        productName,
        identificationResults,
        orderPrice,
        biaobanStatus,
        confirmReviewStatus
    ) {
      this.confirmReviewStatus = confirmReviewStatus * 1
      this.checkReturnVersionPriceStatus = false
      this.orderId = orderId
      this.biaobanStatus = biaobanStatus
      this.orderPrice = orderPrice
      this.identificationResults = identificationResults
      this.productName = productName
      this.orderRatingServiceRank = orderRatingServiceRank
      this.productRemark = productRemark
      this.recordId = id
      this.resetForms()
      await this.getDetailData(id)
      await this.getServiceNoteList('forward')
    },
    /** 获取数据 */
    async getDetailData(id) {
      this.$loading.show()
      const res = await this.axios("/dq_admin/recurRating/detailsToBeConfirmed", {
        params: {recordId: id},
      });
      this.$loading.hide()
      if (res.status !== '200') return;
      this.buyerAddress = res.data.buyerAddress
      this.sellerAddress = res.data.sellerAddress
      this.detailData = res.data;
      if (this.detailData.recurRatingByUserId === 2) {
        this.addressSelectedTypeList = ADDRESS_SELECTED_BY_SELLER_TYPE
      } else {
        this.addressSelectedTypeList = ADDRESS_SELECTED_TYPE
      }
      this.rateServiceType = res.data.service_type
      this.show = true
    },
    handlePreview(arr, index) {
      const imgArray = arr.map(el => {
        return { img_url: el }
      })
      this.$previewImg({
        list: imgArray,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    },
    /** TODO  鉴定操作回退 */
    async handleCheckBack() {
      return this.$message.warning('TODO 功能暂未上线')
      const res = await postConfirmRateCheckBackApi({})
      if (res.status !== '200') return
      this.$message.success(res.message || '成功了');
      this.resetForms()
      this.$emit("success");
    },
    hideModal() {
      if (this.isLoading) return this.$message.warn('请勿重复点击。。。')
      if (!this.params.reviewStatus) return this.$message.warn('评级结果不能为空')
      // if (!this.params.responsibilityDetermine) return this.$message.warn('描述不能空')
      if (!this.params.addressSelectedType && this.params.reviewStatus !== 5) return this.$message.warn('地址类型不能为空')
      if (this.params.ratingPrice === undefined || this.params.ratingPrice === '' || this.params.ratingPrice === null) return this.$message.warn('评级费用不能为空')
      if (this.params.sellerBear === 1 && !this.params.compensationAmount) return this.$message.warn(('补偿金额不能为空'))
      if (this.params.compensationAmount && this.params.compensationAmount % 50 !== 0) return this.$message.warn('补偿金必须为50的倍数')
      this.submit(this.params)
    },
    async submit(params) {
      this.$loading.show()
      const url = '/dq_admin/recurRating/identificationConfirmation'
      this.isLoading = true
      const res = await this.axios.post(url, {
        recordId: this.recordId,
        refundBiaobanStatus: this.checkReturnVersionPriceStatus ? 1 : 0,
        ...params
      });
      this.isLoading = false
      this.$loading.hide()
      if (res.status !== '200') return;
      this.$message.success(res.message || '成功了');
      this.resetForms()
      this.$emit("success");
    },
    resetForms() {
      this.params = {
        reviewStatus: undefined,
        responsibilityDetermine: undefined,
        addressSelectedType: undefined,
        reviewRemarks: undefined,
        ratingPrice: undefined
      }
      this.checkReturnVersionPriceStatus = false
      this.show = false
    },
  },
};
</script>

<style lang="scss" scoped>
.input-ratingPrice {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.result-detail-address {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .buyer-seller-address {
    .address-info-text {
      margin-top: 5px;
      font-size: 15px;
      span {
        font-size: 15px;
        color: #000;
        font-weight: bold;
      }
    }
    .address-title {
      font-size: 15px;
      color: gray;
      font-weight: bold;
    }
  }
}
.result-desc-remark {
  margin-top: 20px;
}
.content-result-img,
.content-check-img,
.content-check-img {
  margin-top: 15px;
  .result-img-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
.content-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  .title-result-text {
    font-size: 15px;
    span {
      font-size: 20px;
      color: #000;
      font-weight: bold;
    }
  }
}
.popup-content {
  width: 100%;
}
.ml-20 {
  margin-left: 20px;
}
.ft-sz-15 {
  font-size: 15px;
}
</style>
