import {getServiceNoteListApi} from "@/views/cmsPage/dqPlatform/orderListAboutManage/orderList/_apis"

export default {
    data() {
        return {}
    },
    methods: {
        /** 获取备注信息 */
        async getServiceNoteList(type) {
            const res = await getServiceNoteListApi({orderId: this.orderId})
            if (res.status !== '200') return
            if (type === 'forward') {
                this.orderNoteByForwardList = res.data
            } else if (type === 'reverse') {
                this.orderNoteByReverseList = res.data
            }
        }
    }
}