<template>
  <div>
    <a-table
        :columns="tableList"
        :data-source="list"
        :pagination="pagination"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   订单   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <a-tooltip>
          <template slot="title">
            {{ computedRateServicePayTypeTextByDesc(row.serviceType) }}
          </template>
          <span :class="dqCheckPayClassType[row.serviceType]">{{ computedRateServicePayTypeText(row.serviceType) }}</span>
        </a-tooltip>
        <div class="mt-10">
          <span @click="handleGoToOrder(row.orderNo)" class="cur-pot color-blue">{{ row.orderNo }}</span>
        </div>
      </div>
<!--   首次评级结果   -->
      <div slot="itemFirstRateResultSlot" slot-scope="row" v-if="row.firstRatingInfo">
<!--    首次评级结果    -->
        <div class="color-black">
          <span class="font-weight-bold color-green">首次</span>评级结果：
          <span>{{ row.firstRatingInfo.identificationResults || '暂无' }}</span>
        </div>
<!--    首次确定状态    -->
        <div class="color-black">
          <span class="font-weight-bold color-green">首次</span>确认状态：
          <span :class="confirmClassType[row.firstRatingInfo.reviewStatus]">{{ computedConfirmTypeText(row.firstRatingInfo.reviewStatus) }}</span>
        </div>
<!--     确认时间   -->
        <div class="color-black">
          <span class="font-weight-bold color-green">首次</span>确认时间：
          <span class="color-gray">{{ row.firstRatingInfo.confirmTime }}</span>
        </div>
<!--    首次评级编号    -->
        <div class="mt-10">
          <span class="color-black font-weight-bold">{{ row.firstRatingInfo.ratingCode }}</span>
          <div v-if="row.firstRatingInfo.reviewStatus === 1">
            <a-button type="primary" class="mt-10" size="small" @click="handleShowChangeRatingCodePopup(row.firstRatingInfo, 'ABC')">首次评级编号编辑</a-button>
          </div>
        </div>
      </div>
<!--   复评结果相关   -->
      <div slot="itemAgainRateResultSlot" slot-scope="row" v-if="row.recurRatingInfo">
<!--    复评用户    -->
        <div class="font-weight-bold color-black">
          复评用户：
          <span :class="row.recurRatingInfo.recurIdentity === 'buyer' ? 'color-blue' : 'color-orange'">{{ row.recurRatingInfo.recurIdentity === 'buyer' ? '买家申请' : '卖家申请' }}</span></div>
<!--    复评评级结果    -->
        <div class="mt-10 color-black">
          <span class="font-weight-bold color-orange">复评</span>评级结果：
          <span>{{ row.recurRatingInfo.identificationResults || '暂无' }}</span>
        </div>
<!--    复评确定状态    -->
        <div class="color-black">
          <span class="font-weight-bold color-orange">复评</span>确认状态：
          <span :class="confirmClassType[row.recurRatingInfo.reviewStatus]">{{ computedConfirmTypeText(row.recurRatingInfo.reviewStatus) }}</span>
        </div>
<!--     确认时间   -->
        <div class="color-black">
          <span class="font-weight-bold color-orange">复评</span>确认时间：
          <span class="color-gray">{{ row.recurRatingInfo.confirmTime }}</span>
        </div>
<!--    复评评级编号    -->
        <div class="mt-10">
          <span class="color-black font-weight-bold">{{ row.recurRatingInfo.ratingCode }}</span>
          <div v-if="row.recurRatingInfo.reviewStatus === 1">
            <a-button type="danger" class="mt-10" size="small" @click="handleShowChangeRatingCodePopup(row.recurRatingInfo, 'againABC')">复评评级编号编辑</a-button>
          </div>
        </div>
      </div>
<!--   发货单号   -->
      <div slot="itemSenderExpressNoSlot" slot-scope="row">
        <span :class="row.expressNo ? 'color-green cur-pot' : 'color-gray'" >{{ row.expressNo ? row.expressNo : '暂无' }}</span>
      </div>
<!--   买家，卖家选择   -->
      <div slot="itemBuyerOrSellerSelectedSlot" slot-scope="row">
        <div class="color-black">
          鉴定后<span class="font-weight-bold color-blue">买家</span>选择：
          <div :class="buyerSelAfterRatingClassType[row.buyerSelAfterRating]">{{ computedBuyerSelAfterRatingTypeListMapText(row.buyerSelAfterRating) || '无' }}</div>
        </div>
        <div class="mt-20 color-black">
          退货<span class="font-weight-bold color-orange">卖家</span>选择：
          <div :class="buyerSelAfterRatingClassType[row.sellerSelReturnAfterBuyer]">{{ computedSellerSelReturnAfterBuyerTypeListMapText(row.sellerSelReturnAfterBuyer) || '无' }}</div>
        </div>
      </div>
<!--   操作  -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button v-if="row.firstRatingInfo" size="small" type="primary" @click="handleShowConfirm(row)">{{ row.firstRatingInfo.reviewStatus === 2 ? '查看首次评级详情' : '首次评级确认' }}</a-button>
        <a-button v-if="row.recurRatingInfo" class="mt-20" size="small" type="danger" @click="handleShowAgainConfirm(row)">{{ row.recurRatingInfo.reviewStatus === 2 ? '查看复评评级详情' : '复评评级确认' }}</a-button>
      </div>
    </a-table>
    <ConfirmDetailPopup ref="confirmDetailPopupEl" @success="handleConfirmSuccess"/>
    <AgainConfirmDetailPopup ref="againConfirmDetailPopupEl" @success="handleConfirmSuccess"/>
<!--  编辑评级编号  -->
    <EditorRatingCodePopup
      ref="editorRatingCodePopupEl"
      :rate-type="rateType"
      @change="handleConfirmSuccess"
    />
  </div>
</template>
<script>
import {
  buyerSelAfterRatingClassType,
  buyerSelAfterRatingTypeListMapText,
  CONFIRM_CLASS_TYPE,
  dqCheckPayClassType,
  RATE_RESULT_CLASS_TYPE_LIST,
  rateConfirmTypeMapText,
  rateResultTypeMapText,
  rateServicePayTypeMapText,
  rateServicePayTypeMapTextByDesc,
  sellerSelReturnAfterBuyerTypeListMapText
} from "@/views/cmsPage/checkManage/_data"
import {dqCheckResultConfirmListTableColumns} from "@/views/cmsPage/checkManage/resultList/_data"
import ConfirmDetailPopup from "@/views/cmsPage/checkManage/resultList/_components/ConfirmDetailPopup"
import EditorRatingCodePopup from "@/views/cmsPage/checkManage/_components/EditorRatingCodePopup"
import {productRemarkStringParseToJson} from "@/_service"
import AgainConfirmDetailPopup
  from "@/views/cmsPage/checkManage/resultList/_components/AgainConfirmDetailPopup/index.vue"

export default {
  props: ['list', 'pagination'],
  components: {ConfirmDetailPopup, EditorRatingCodePopup, AgainConfirmDetailPopup},
  data() {
    return {
      tableList: dqCheckResultConfirmListTableColumns,
      rateResultClassType: RATE_RESULT_CLASS_TYPE_LIST,
      confirmClassType: CONFIRM_CLASS_TYPE,
      dqCheckPayClassType,
      buyerSelAfterRatingClassType,
      rateType: 'ABC',
    }
  },
  computed: {
    computedRemarkShow() {
      return (str) => productRemarkStringParseToJson(str)
    },
    computedRateServicePayTypeTextByDesc() {
      return (value) => rateServicePayTypeMapTextByDesc(value)
    },
    computedBuyerSelAfterRatingTypeListMapText() {
      return (value) => buyerSelAfterRatingTypeListMapText(value)
    },
    computedSellerSelReturnAfterBuyerTypeListMapText() {
      return (value) => sellerSelReturnAfterBuyerTypeListMapText(value)
    },
    computedRateServicePayTypeText() {
      return (value) => rateServicePayTypeMapText(value)
    },
    computedResultTypeText() {
      return (value) => rateResultTypeMapText(value)
    },
    computedConfirmTypeText() {
      return (value) => rateConfirmTypeMapText(value)
    }
  },
  methods: {
    /** 修改评级编号 */
    handleShowChangeRatingCodePopup(row, rateType) {
      this.rateType = rateType
      const tempData = {
        ratingCode: row.ratingCode,
        ratingId: row.id
      }
      this.$refs.editorRatingCodePopupEl.show(tempData)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 去往订单页面 */
    handleGoToOrder(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/newordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    /** 展示详情 */
    handleShowConfirm(row) {
      this.$refs.confirmDetailPopupEl.showPopup(
          row.recordId,
          row.orderId,
          row.productRemark,
          row.serviceType,
          row.productName,
          row.firstRatingInfo.identificationResults,
          row.orderPrice,
          row.firstRatingInfo.biaobanStatus,
          row.firstRatingInfo.reviewStatus
      )
    },
    /** 展示复评详情 */
    handleShowAgainConfirm(row) {
      this.$refs.againConfirmDetailPopupEl.showPopup(
          row.recordId,
          row.orderId,
          row.productRemark,
          row.serviceType,
          row.productName,
          row.recurRatingInfo.identificationResults,
          row.orderPrice,
          row.recurRatingInfo.biaobanStatus,
          row.recurRatingInfo.reviewStatus
      )
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        current: index,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    handleConfirmSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
.color-green {
  color: green;
}
.color-red {
  color: red;
}
</style>