/** 列表 */
export const dqCheckResultConfirmListTableColumns = [
    { title: "拍品名称", align: "center", dataIndex: 'productName', width: 150 },
    { title: "订单号", align: "center", scopedSlots: { customRender: "itemOrderNoSlot" }, width: 200 },
    { title: "首次评级相关", align: "left", scopedSlots: { customRender: "itemFirstRateResultSlot" }, width: 250 },
    { title: "复评评级相关", align: "left", scopedSlots: { customRender: "itemAgainRateResultSlot" }, width: 250 },
    { title: "买/卖是否选择", align: "left", scopedSlots: { customRender: "itemBuyerOrSellerSelectedSlot" }, width: 250 },
    { title: "发货单号", align: "center", scopedSlots: { customRender: 'itemSenderExpressNoSlot'}, width: 200},
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]

/** 说明类型 */
export const DESC_TYPE_LIST = [
    { name: '描述不符', value: 20},
    { name: '掩盖瑕疵', value: 25},
    { name: '卖家无责', value: 30},
    { name: '有备注', value: 35},
]

/** 地址选择类型 */
export const ADDRESS_SELECTED_TYPE = [
    { name: '发货至买家', value: 1},
    { name: '退货至卖家', value: 2},
    { name: '让买家选择是否要', value: 3},
]

export const ADDRESS_SELECTED_BY_SELLER_TYPE = [
    { name: '退货至卖家', value: 2},
]

/** 选择评级费用等级-----new */
export const RATE_PRICE_LEVEL_TYPE_LIST = [
    { name: '无需评级费', value: 0 },
    { name: '40元    A档', value: 40 },
    { name: '100元    B档', value: 100 },
    { name: '160元   C档', value: 160 },
    { name: '340元   D档', value: 340 },
    { name: '980元   E档', value: 980 },
]

/** 选择评级费用等级-----new */
export const AGAIN_RATE_PRICE_LEVEL_TYPE_LIST = [
    { name: '无需评级费', value: 0 },
    { name: '60元    A档', value: 60 },
    { name: '120元    B档', value: 120 },
    { name: '180元   C档', value: 180 },
    { name: '360元   D档', value: 360 },
    { name: '1000元   E档', value: 1000 },
]

/** 评级类型 */
export const firstOrAgainRateTypeList = [
    { name: '首次评级', value: 1 },
    { name: '复评', value: 2 },
]

// /** 选择评级费用等级----old */
// export const RATE_PRICE_LEVEL_TYPE_LIST = [
//     { name: '无需评级费', value: 0 },
//     { name: '30元    A类', value: 30 },
//     { name: '80元    B类', value: 80 },
//     { name: '130元   C类', value: 130 },
//     { name: '280元   D类', value: 280 },
//     { name: '980元   E类', value: 980 },
// ]
