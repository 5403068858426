var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          "data-source": _vm.list,
          pagination: _vm.pagination,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u(
          [
            {
              key: "itemOrderNoSlot",
              fn: function(row) {
                return _c(
                  "div",
                  {},
                  [
                    _c(
                      "a-tooltip",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.computedRateServicePayTypeTextByDesc(
                                  row.serviceType
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "span",
                          { class: _vm.dqCheckPayClassType[row.serviceType] },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.computedRateServicePayTypeText(
                                  row.serviceType
                                )
                              )
                            )
                          ]
                        )
                      ],
                      2
                    ),
                    _c("div", { staticClass: "mt-10" }, [
                      _c(
                        "span",
                        {
                          staticClass: "cur-pot color-blue",
                          on: {
                            click: function($event) {
                              return _vm.handleGoToOrder(row.orderNo)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.orderNo))]
                      )
                    ])
                  ],
                  1
                )
              }
            },
            {
              key: "itemFirstRateResultSlot",
              fn: function(row) {
                return row.firstRatingInfo
                  ? _c("div", {}, [
                      _c("div", { staticClass: "color-black" }, [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold color-green" },
                          [_vm._v("首次")]
                        ),
                        _vm._v("评级结果： "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.firstRatingInfo.identificationResults ||
                                "暂无"
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "color-black" }, [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold color-green" },
                          [_vm._v("首次")]
                        ),
                        _vm._v("确认状态： "),
                        _c(
                          "span",
                          {
                            class:
                              _vm.confirmClassType[
                                row.firstRatingInfo.reviewStatus
                              ]
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.computedConfirmTypeText(
                                  row.firstRatingInfo.reviewStatus
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "color-black" }, [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold color-green" },
                          [_vm._v("首次")]
                        ),
                        _vm._v("确认时间： "),
                        _c("span", { staticClass: "color-gray" }, [
                          _vm._v(_vm._s(row.firstRatingInfo.confirmTime))
                        ])
                      ]),
                      _c("div", { staticClass: "mt-10" }, [
                        _c(
                          "span",
                          { staticClass: "color-black font-weight-bold" },
                          [_vm._v(_vm._s(row.firstRatingInfo.ratingCode))]
                        ),
                        row.firstRatingInfo.reviewStatus === 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticClass: "mt-10",
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleShowChangeRatingCodePopup(
                                          row.firstRatingInfo,
                                          "ABC"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("首次评级编号编辑")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ])
                  : _vm._e()
              }
            },
            {
              key: "itemAgainRateResultSlot",
              fn: function(row) {
                return row.recurRatingInfo
                  ? _c("div", {}, [
                      _c(
                        "div",
                        { staticClass: "font-weight-bold color-black" },
                        [
                          _vm._v(" 复评用户： "),
                          _c(
                            "span",
                            {
                              class:
                                row.recurRatingInfo.recurIdentity === "buyer"
                                  ? "color-blue"
                                  : "color-orange"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.recurRatingInfo.recurIdentity === "buyer"
                                    ? "买家申请"
                                    : "卖家申请"
                                )
                              )
                            ]
                          )
                        ]
                      ),
                      _c("div", { staticClass: "mt-10 color-black" }, [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold color-orange" },
                          [_vm._v("复评")]
                        ),
                        _vm._v("评级结果： "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.recurRatingInfo.identificationResults ||
                                "暂无"
                            )
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "color-black" }, [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold color-orange" },
                          [_vm._v("复评")]
                        ),
                        _vm._v("确认状态： "),
                        _c(
                          "span",
                          {
                            class:
                              _vm.confirmClassType[
                                row.recurRatingInfo.reviewStatus
                              ]
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.computedConfirmTypeText(
                                  row.recurRatingInfo.reviewStatus
                                )
                              )
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "color-black" }, [
                        _c(
                          "span",
                          { staticClass: "font-weight-bold color-orange" },
                          [_vm._v("复评")]
                        ),
                        _vm._v("确认时间： "),
                        _c("span", { staticClass: "color-gray" }, [
                          _vm._v(_vm._s(row.recurRatingInfo.confirmTime))
                        ])
                      ]),
                      _c("div", { staticClass: "mt-10" }, [
                        _c(
                          "span",
                          { staticClass: "color-black font-weight-bold" },
                          [_vm._v(_vm._s(row.recurRatingInfo.ratingCode))]
                        ),
                        row.recurRatingInfo.reviewStatus === 1
                          ? _c(
                              "div",
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticClass: "mt-10",
                                    attrs: { type: "danger", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleShowChangeRatingCodePopup(
                                          row.recurRatingInfo,
                                          "againABC"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("复评评级编号编辑")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ])
                  : _vm._e()
              }
            },
            {
              key: "itemSenderExpressNoSlot",
              fn: function(row) {
                return _c("div", {}, [
                  _c(
                    "span",
                    {
                      class: row.expressNo
                        ? "color-green cur-pot"
                        : "color-gray"
                    },
                    [_vm._v(_vm._s(row.expressNo ? row.expressNo : "暂无"))]
                  )
                ])
              }
            },
            {
              key: "itemBuyerOrSellerSelectedSlot",
              fn: function(row) {
                return _c("div", {}, [
                  _c("div", { staticClass: "color-black" }, [
                    _vm._v(" 鉴定后"),
                    _c("span", { staticClass: "font-weight-bold color-blue" }, [
                      _vm._v("买家")
                    ]),
                    _vm._v("选择： "),
                    _c(
                      "div",
                      {
                        class:
                          _vm.buyerSelAfterRatingClassType[
                            row.buyerSelAfterRating
                          ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedBuyerSelAfterRatingTypeListMapText(
                              row.buyerSelAfterRating
                            ) || "无"
                          )
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "mt-20 color-black" }, [
                    _vm._v(" 退货"),
                    _c(
                      "span",
                      { staticClass: "font-weight-bold color-orange" },
                      [_vm._v("卖家")]
                    ),
                    _vm._v("选择： "),
                    _c(
                      "div",
                      {
                        class:
                          _vm.buyerSelAfterRatingClassType[
                            row.sellerSelReturnAfterBuyer
                          ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedSellerSelReturnAfterBuyerTypeListMapText(
                              row.sellerSelReturnAfterBuyer
                            ) || "无"
                          )
                        )
                      ]
                    )
                  ])
                ])
              }
            },
            {
              key: "itemActionSlot",
              fn: function(row) {
                return _c(
                  "div",
                  {},
                  [
                    row.firstRatingInfo
                      ? _c(
                          "a-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowConfirm(row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.firstRatingInfo.reviewStatus === 2
                                  ? "查看首次评级详情"
                                  : "首次评级确认"
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    row.recurRatingInfo
                      ? _c(
                          "a-button",
                          {
                            staticClass: "mt-20",
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowAgainConfirm(row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.recurRatingInfo.reviewStatus === 2
                                  ? "查看复评评级详情"
                                  : "复评评级确认"
                              )
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }
            }
          ],
          null,
          true
        )
      }),
      _c("ConfirmDetailPopup", {
        ref: "confirmDetailPopupEl",
        on: { success: _vm.handleConfirmSuccess }
      }),
      _c("AgainConfirmDetailPopup", {
        ref: "againConfirmDetailPopupEl",
        on: { success: _vm.handleConfirmSuccess }
      }),
      _c("EditorRatingCodePopup", {
        ref: "editorRatingCodePopupEl",
        attrs: { "rate-type": _vm.rateType },
        on: { change: _vm.handleConfirmSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }